<template>
  <div class="bodybox">
    <!--表格列表-->
    <div class="detail">
      <el-table :data="tableData" :empty-text = "$t('musicailistkong')" style="width: 100%;"  >
        <el-table-column  :label="$t('musicaizuopin')" align="center" width="500">
          <template slot-scope="scope">
            <div class="music">
              <div class="list_loding" v-if="scope.row.music_loading == 'wait'">
                <i  class="el-icon-loading" style="margin-right: 10px;font-size: 30px"></i>AI创作中，正在排队...
              </div>
              <div class="list_lodings" v-if="scope.row.music_loading == 'online'">
                <el-progress style="width:400px" :text-inside="true" :stroke-width="20" :percentage="percentage" status="success">11</el-progress>
              </div>
              <div class="list_loding" v-if="scope.row.music_loading == 'fail'">
                <i  class="el-icon-hot-water" style="margin-right: 10px;font-size: 30px"></i>AI创作失败...
              </div>
              <div class="list" v-else v-for="(item, index) in scope.row.music" :key="index">
                <music-ai-bottom :type="'reference'" @xiazai="xiazai" @geci="geci" :obj="item"></music-ai-bottom>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="lyric" :label="$t('musicaigequgegeci')" align="center">
          <template slot-scope="scope">
            <el-scrollbar wrap-style="overflow-x:hidden;" style="height: 300px;overflow-x: hidden">
                <div v-html="scope.row.lyric.replace(/\n/g, '<br />')"></div>
            </el-scrollbar>
          </template>
        </el-table-column>
        <el-table-column prop="label" :label="$t('musicaimiaoshu')" width="200" align="center"></el-table-column>
<!--        <el-table-column prop="task_id" label="ID" width="200" align="center"></el-table-column>-->
<!--        <el-table-column prop="style" :label="$t('musicaimiaoshu')" width="200" align="center"></el-table-column>-->
      </el-table>
    </div>
    <!--歌词盒子-->
    <!--<div class="geci_box"></div>-->
  </div>

</template>

<script>
import MusicAiBottom from "@/components/content/MusicAIBottom";
import PopupCommon from "@/components/popup/PopupCommon.vue"
import {
  getLiked,
  getLiking,
  musicAIGet,
  musicAIMake,
} from "@/api/Mine";
export default {
  name: "MusicaiList",
  components: {
    MusicAiBottom,
  },
  data() {
    return {
      percentage:0,
      dialogVisible: false,
      visible: false,
      gecishow: false,
      lyric:'',
      loading: true,
      tableData: [],
      tabsList:[
        {
          name: this.$t('musicAI'),
          url: "/musicai/musicai",
        },
        {
          name: this.$t('mymusicAI'),
          url: "/musicai/musicaiList",
        },
      ]
    }
  },
  created() {
    this.getMyMusicAiList();
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    // 获取我的作品列表
    getMyMusicAiList() {
      musicAIGet({}).then((res) => {
        this.loading = false;
        if (res.status == 1) {
          //当最新的作曲还在创作时候，轮询查询，直到接口返回repeat=0
          if(res.repeat == 1){
            if(this.percentage <= 95) this.percentage +=5
            if(this.percentage == 95) this.percentage +=4

            setTimeout(this.getMyMusicAiList, 5000);
          }
          if(res.repeat == 0) this.percentage = 100
          this.tableData = res.data;
        } else {
          this.$message.error(res.msg)
        }
      }).catch((err) => {
        console.log(err, '获取失败');
      });
    },

    // 下载音频
    xiazai(data) {
      let url = data.url;
      let title = data.title;
      console.log('下载音频', url)

      const a = document.createElement('a')
      a.href = url
      a.download = title // 下载后文件名
      a.style.display = 'none'
      a.target = '_blank'
      document.body.appendChild(a)
      a.click() // 点击下载
      document.body.removeChild(a) // 下载完成移除元素

    },
    // 歌词
    geci(data) {
      this.$notify({
        title: data.title,
        offset: 100,
        dangerouslyUseHTMLString:true,
        message: data.lyric,
        duration: 0
      });
    },
  },
}
</script>
<style scoped  lang='less'>


  .bodybox {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .detail {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .geci_box {
    display: flex;
    position: fixed;
    right: 80px;
    background-color: #06D5BA;
    width: 250px;
    height: 600px;
  }

  ::v-deep .el-table th{
    background-color: @baseBackgroundColor;
    color: @headerSelectTextColor  !important;
  }
  ::v-deep .el-table tr {
    background-color: @baseBackgroundColor;
    color: @headerSelectTextColor  !important;
  }
  ::v-deep .el-table tr:hover > td{
    background-color: transparent !important;
  }
  ::v-deep  .el-table__empty-block{
    background-color: @baseBackgroundColor;
  }

  ::v-deep .el-table__empty-text {
    color: @headerSelectTextColor  !important;
  }


  ::v-deep .music .list .main .bottom{
    padding-top: 10px;
  }

  .music {
    width: 100%;
    // height: 500px;
    display: flex;
    flex-direction: row;
    align-content: center;

    .list_lodings {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 20px;
      margin-right: 50px;
    }
    .list_loding {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 20px;
      margin-right: 200px;
    }
    .list {
      display: flex;
      margin-bottom: 20px;
      margin-right: 20px;
      width: 220px;
      //width: 18%;
    }

    ///* 如果最后一行是4个元素 */
    //.list:last-child:nth-child(5n - 1) {
    //  margin-right: calc(18% + 10% / 4);
    //}
    //
    ///* 如果最后一行是3个元素 */
    //.list:last-child:nth-child(5n - 2) {
    //  margin-right: calc(36% + 20% / 4);
    //}
    //
    ///* 如果最后一行是2个元素 */
    //.list:last-child:nth-child(5n - 3) {
    //  margin-right: calc(54% + 30% / 4);
    //}
  }


</style>